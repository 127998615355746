import { notifySuccess } from '@/src/shared/utils/message';
import type { AxiosResponse } from 'axios';
import { empty } from '@/core/helpers';
import { callGetApi, callPostApi } from '@/src/core/utils/api';
import { updateQueryStringParameter } from '@/core/utility';
import { doesNeedUpgrade } from '@/helpers/user-feature';
import { UpgradePlans } from '@/types/subscription';
import { AnalyticApis } from '@/pages/api/analytics';
import {
  openFacebookBusinessConnectionConfirm,
  openNewPopup,
} from '../popup-manager/actions';
import type { RootState } from '../reducer';
import { updateUserInfo } from '../user/actions';
import {
  selectConnectedPlatforms,
  selectIsBasicPlanHidden,
  selectIsConntected,
  selectIsFreeUser,
  selectIsLimitedConnect,
  selectUser,
  selectUserInfo,
} from '../user/selectors';
import {
  CANCEL_CONNECT_SOCIAL,
  FAILURE_CONNECT_SOCIAL,
  REQUEST_CONNECT_SOCIAL,
  RESET_CONNECT_SOCIAL,
  SET_CONNECT_SOCIAL_CREDENTIALS,
  SET_CONNECT_SOCIAL_FACEBOOK_PAGES,
  SET_CONNECT_SOCIAL_GOOGLE_MY_BUSINESS_PAGES,
  SET_CONNECT_SOCIAL_INSTAGRAM_BUSINESS_PAGES,
  SET_CONNECT_SOCIAL_LINKEDIN_PAGES,
  SET_CONNECT_SOCIAL_LOADING,
  SET_CONNECT_SOCIAL_PAGE,
  SUCCESS_CONNECT_SOCIAL,
} from './actionTypes';
import {
  selectConnectCssTrackingSource,
  selectConnectOnSuccess,
  selectConnectShowFailurePopup,
} from './selectors';
import {
  ConnectSocialThunkDispatch,
  ConnectSocialThunkResult,
  FacebookPageItem,
  FailureConnectSocialAction,
  GoogleBizPageItem,
  LinkedinPageItem,
  RequestConnectSocialAction,
  ResetConnectSocialAction,
  SetConnectSocialCredentialsAction,
  SetConnectSocialFacebookPagesAction,
  SetConnectSocialGoogleBizPagesAction,
  SetConnectSocialInstagramBusinessPagesAction,
  SetConnectSocialLinkedinPagesAction,
  SetConnectSocialLoadingAction,
  SetConnectSocialPageAction,
  SuccessConnectSocialAction,
} from './types';
import { IUser } from '@/types/user';
import { isMobile } from 'react-device-detect';
import { selectPageSection } from '../popup-manager/selectors';
import { PageSections } from '@/types/pages';
import { getPlatformName } from '@/src/shared/utils/socials';
import { Socials } from '@/src/shared/types/socials';
import { connectPlatformUrl } from './constants';

// ACTIONS
const requestConnectSocial = (
  platform: string,
  page: string,
  cssTrackingSource: string,
  showFailurePopup: boolean,
  isFrom: string,
  excludePlatforms: string[],
  onSuccess?: () => void,
  closeAll?: boolean
): RequestConnectSocialAction => ({
  type: REQUEST_CONNECT_SOCIAL,
  payload: {
    platform,
    page,
    cssTrackingSource,
    showFailurePopup,
    isFrom,
    excludePlatforms,
    onSuccess,
    closeAll,
  },
});

export const resetConnectSocial = (): ResetConnectSocialAction => ({
  type: RESET_CONNECT_SOCIAL,
});

const successConnectSocial = (): SuccessConnectSocialAction => ({
  type: SUCCESS_CONNECT_SOCIAL,
});

export const cancelConnectSocial = () => ({ type: CANCEL_CONNECT_SOCIAL });

export const failureConnectSocial = (
  error: string
): FailureConnectSocialAction => ({
  type: FAILURE_CONNECT_SOCIAL,
  payload: {
    error,
  },
});

export const setConnectSocialPage = (
  page: string,
  plan?: string
): SetConnectSocialPageAction => ({
  type: SET_CONNECT_SOCIAL_PAGE,
  payload: {
    page,
    plan,
  },
});

export const setConnectSocialCredentials = (
  req_id?: string,
  username?: string,
  password?: string,
  code?: string,
  message?: string
): SetConnectSocialCredentialsAction => ({
  type: SET_CONNECT_SOCIAL_CREDENTIALS,
  payload: {
    credentials: {
      req_id,
      username,
      password,
      code,
      message,
    },
  },
});

export const setConnectSocialLoading = (
  loading: boolean
): SetConnectSocialLoadingAction => ({
  type: SET_CONNECT_SOCIAL_LOADING,
  payload: {
    loading,
  },
});

const setConnectSocialFacebookPages = (
  facebookPages: FacebookPageItem[],
  platformType: string
): SetConnectSocialFacebookPagesAction => ({
  type: SET_CONNECT_SOCIAL_FACEBOOK_PAGES,
  payload: {
    facebookPages,
    platformType,
  },
});

const setConnectSocialBizPages = (
  googleBizPages: GoogleBizPageItem[],
  platformType: string
): SetConnectSocialGoogleBizPagesAction => ({
  type: SET_CONNECT_SOCIAL_GOOGLE_MY_BUSINESS_PAGES,
  payload: {
    googleBizPages,
    platformType,
  },
});

const setConnectSocialLinkedinPages = (
  linkedinPages: LinkedinPageItem[]
): SetConnectSocialLinkedinPagesAction => ({
  type: SET_CONNECT_SOCIAL_LINKEDIN_PAGES,
  payload: {
    linkedinPages,
  },
});

const setConnectSocialInstagramBusinessPages = (
  instagramBusinessPages: FacebookPageItem[]
): SetConnectSocialInstagramBusinessPagesAction => ({
  type: SET_CONNECT_SOCIAL_INSTAGRAM_BUSINESS_PAGES,
  payload: {
    instagramBusinessPages,
  },
});

const startConnect = async (
  dispatch,
  platform,
  callbackUrl,
  showFailurePopup
) => {
  try {
    const response: AxiosResponse<{
      data: { redirect_url: string };
      error: boolean;
    }> = await callPostApi('/calendar/start-connect', {
      callback_url: updateQueryStringParameter(
        callbackUrl,
        'connect_social',
        platform
      ),
      platform,
    });

    window.location.assign(response.data.data.redirect_url);
  } catch (e) {
    dispatch(failureConnectSocial('failed to connect!'));
    if (showFailurePopup) {
      dispatch(setConnectSocialPage('cannot'));
    } else {
      dispatch(setConnectSocialPage('none'));
    }
  }
};

const openWindow = (url) => {
  return new Promise((acc: any) => {
    let win = window.open(
      url,
      'popupWindow',
      'width=600,height=600,scrollbars=yes'
    );
    const popupTick = setInterval(function () {
      try {
        if (win.closed) {
          clearInterval(popupTick);
          acc();
        }
      } catch (err) {
        clearInterval(popupTick);
      }
    }, 100);
  });
};

const success = (
  dispatch,
  text,
  platform,
  cssTrackingSource,
  userId,
  pageSection: PageSections,
  onSuccess?: () => void
) => {
  notifySuccess(text);

  const intercomPayload = {
    page_location: window.location.pathname,
    // page_name: getPageName(),
    is_mobile: isMobile,
    social_name: getPlatformName(platform),
    // included_in_plan: true/false,
    // subscription_type: user?.subscription,
    page_section: pageSection,
  };
  AnalyticApis.gaEvent('connect_social_successful', {
    page_location: window.location.pathname,
    social: platform,
    is_mobile: isMobile,
    user_id: userId,
    product_scenario: cssTrackingSource,
    page_section: pageSection,
    intercomPayload: intercomPayload,
  });

  if (platform === 'facebook') {
    dispatch(openFacebookBusinessConnectionConfirm());
  } else {
    dispatch(successConnectSocial());
  }

  if (
    !onSuccess &&
    (platform === 'instagram' || platform === 'instagram_business')
  ) {
    dispatch(afterInstagramSave(platform));
  } else if (!onSuccess && platform === 'tiktok') {
    dispatch(openNewPopup('tiktok-share-explanations-popup'));
  } else {
    dispatch(setConnectSocialPage('none'));
    onSuccess && onSuccess();
  }
};

const handleFailedConnect = (
  dispatch,
  showFailurePopup: boolean,
  platform: string = '',
  cssTrackingSource: string,
  pageSection: PageSections,
  user: IUser,
  failureMessage: string = 'failed to connect!'
) => {
  AnalyticApis.gaEvent('connect_social_failed', {
    page_location: window.location.pathname,
    social: platform,
    is_mobile: isMobile,
    user_id: user?.id,
    subscription_type: user?.subscription,
    product_scenario: cssTrackingSource,
    page_section: pageSection,
  });
  dispatch(failureConnectSocial(failureMessage));
  if (showFailurePopup) {
    platform === 'linkedin'
      ? dispatch(setConnectSocialPage('connot-linkedin'))
      : dispatch(setConnectSocialPage('cannot'));
  } else {
    dispatch(setConnectSocialPage('none'));
  }
};

const getLinkedinPages = async (
  dispatch,
  showFailurePopup: boolean,
  platform: string,
  pageSection: PageSections,
  user: IUser
) => {
  dispatch(setConnectSocialLoading(true));

  try {
    const response: AxiosResponse<{
      result: LinkedinPageItem[];
      error: boolean;
    }> = await callGetApi('/social/linkedin/get-pages');

    const pages = response.data.result;

    if (pages.length < 1) {
      handleFailedConnect(
        dispatch,
        showFailurePopup,
        platform,
        '',
        pageSection,
        user,
        ''
      );
    } else {
      dispatch(setConnectSocialLinkedinPages(pages));
      dispatch(setConnectSocialPage('linkedin'));
    }
  } catch (err) {
    handleFailedConnect(
      dispatch,
      showFailurePopup,
      platform,
      '',
      pageSection,
      user
    );
  } finally {
    dispatch(setConnectSocialLoading(false));
  }
};

const getGoogleBizPages = async (
  dispatch,
  showFailurePopup: boolean,
  platform: string,
  pageSection: PageSections,
  user: IUser
) => {
  dispatch(setConnectSocialLoading(true));

  try {
    const response: AxiosResponse<{
      data: GoogleBizPageItem;
      error: boolean;
    }> = await callGetApi(
      '/v1/agent-dashboard/google-business/locations?user_id=' + user.id,
      {
        platform,
      }
    );

    const pages = response.data.data;
    if (pages.locations.length < 1) {
      handleFailedConnect(
        dispatch,
        showFailurePopup,
        platform,
        '',
        pageSection,
        user,
        'GOOGLE_MY_BUSINESS_DOES_NOT_HAVE_BUSINESS_PAGE'
      );
    } else {
      dispatch(setConnectSocialBizPages(pages.locations, platform));
      dispatch(setConnectSocialPage('google_my_business'));
    }
  } catch (err) {
    handleFailedConnect(
      dispatch,
      showFailurePopup,
      platform,
      '',
      pageSection,
      user
    );
  } finally {
    dispatch(setConnectSocialLoading(false));
  }
};

const getFacebookPages = async (
  dispatch,
  showFailurePopup: boolean,
  platform: string,
  pageSection: PageSections,
  user: IUser
) => {
  dispatch(setConnectSocialLoading(true));

  try {
    const response: AxiosResponse<{
      result: FacebookPageItem[];
      error: boolean;
    }> = await callGetApi('/social/facebook/get-pages', { platform });

    const pages = response.data.result;

    if (pages.length < 1) {
      handleFailedConnect(
        dispatch,
        showFailurePopup,
        platform,
        '',
        pageSection,
        user,
        'FACEBOOK_DOES_NOT_HAVE_BUSINESS_PAGE'
      );
    } else {
      dispatch(setConnectSocialFacebookPages(pages, platform));
      dispatch(setConnectSocialPage('facebook'));
    }
  } catch (err) {
    handleFailedConnect(
      dispatch,
      showFailurePopup,
      platform,
      '',
      pageSection,
      user
    );
  } finally {
    dispatch(setConnectSocialLoading(false));
  }
};

const getInstagramBusinessPages = async (
  dispatch,
  showFailurePopup: boolean,
  user: IUser,
  pageSection: PageSections
) => {
  dispatch(setConnectSocialLoading(true));

  try {
    const response: AxiosResponse<{
      result: FacebookPageItem[];
      error: boolean;
    }> = await callGetApi('/social/facebook/get-pages', {
      platform: 'instagram_business',
      check_permissions: true,
      disconnect_on_insufficient_permissions: true,
    });

    dispatch(setConnectSocialLoading(false));
    let pages = response.data.result;

    if (response.data.error) {
      handleFailedConnect(
        dispatch,
        showFailurePopup,
        'instagram_business',
        '',
        pageSection,
        user
      );
    } else if (pages.length == 0) {
      dispatch(setConnectSocialInstagramBusinessPages(pages));
      dispatch(setConnectSocialPage('instagram-business'));
      dispatch(
        failureConnectSocial("We couldn't find any Facebook Business Page")
      );
    } else {
      dispatch(setConnectSocialInstagramBusinessPages(pages));
      dispatch(setConnectSocialPage('instagram-business-page'));
    }
  } catch (err) {
    let message = 'Please try again';
    if (err?.response?.data?.result === 'INSUFFICIENT_PERMISSIONS') {
      if (user.user_accounts['facebook'].enabled) {
        message =
          'You need to accept all the permissions requested, please disconnect your facebook connection and try again';
      } else {
        message =
          'You need to accept all the permissions requested, please try again';
      }

      dispatch(setConnectSocialPage('instagram-business'));
      dispatch(failureConnectSocial(message));
    }
  }
};

const saveInstagramCredentials = async (
  dispatch,
  cssTrackingSource,
  userId,
  onSuccess,
  states
) => {
  dispatch(updateUserInfo(selectUser(states).token, false)).then((user) => {
    success(
      dispatch,
      'Successfully connected to Instagram.',
      'instagram',
      cssTrackingSource,
      userId,
      selectPageSection(states),
      onSuccess
    );
  });
};

const checkInstagramProcess = async (options: {
  refId: any;
  securityCheck: any;
  failed: any;
  succeed: any;
  passWaitForCode: boolean;
}) => {
  try {
    const response: AxiosResponse<{
      error: boolean;
      result: {
        message: string;
        status:
          | 'IN_PROGRESS'
          | 'FAILED'
          | 'WAIT_FOR_CODE'
          | 'WAIT_FOR_NOTIFICATION_APPROVAL'
          | 'DONE';
        reason: { action: string; description: string; link: string };
      };
    }> = await callPostApi('/social/instagram/check-post-status', {
      refId: options.refId,
    });

    if (response.data.error) {
      options.failed(response.data.result.message || 'Error occurred');
      return;
    }

    const { status, reason } = response.data.result;
    if (status === 'IN_PROGRESS') {
      setTimeout(() => {
        checkInstagramProcess(options);
      }, 4000);
    } else if (status === 'FAILED') {
      options.failed(reason?.description || response.data.result.message);
    } else if (status === 'WAIT_FOR_NOTIFICATION_APPROVAL') {
      setTimeout(() => {
        checkInstagramProcess(options);
      }, 4000);
      options.securityCheck(status);
    } else if (status === 'WAIT_FOR_CODE') {
      if (options.passWaitForCode) {
        setTimeout(() => {
          checkInstagramProcess(options);
        }, 4000);
      } else {
        options.securityCheck(status);
      }
    } else if (status === 'DONE') {
      options.succeed();
    }
  } catch (e) {
    console.error(e);
    options.failed('Something went wrong');
  }
};

export const connectCalendarCallback =
  (platform: string): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    dispatch(requestConnectSocial(platform, 'main', '', true, 'none', []));

    const states = getState();
    let user = selectUserInfo(states);
    let pageSection = selectPageSection(states);
    const state = user.user_accounts[platform];
    switch (platform) {
      case 'facebook':
      case 'facebook_2':
        if (state.enabled && !state.token_expired) {
          success(
            dispatch,
            'Successfully connected to Facebook.',
            platform,
            '', //cssTrackingSource,
            user.id,
            selectPageSection(states)
          );
        } else {
          getFacebookPages(dispatch, true, platform, pageSection, user);
        }
        return;

      case 'linkedin':
        if (state.enabled && !state.token_expired) {
          success(
            dispatch,
            'Successfully connected to LinkedIn.',
            platform,
            '', //cssTrackingSource,
            user.id,
            selectPageSection(states)
          );
        } else {
          getLinkedinPages(dispatch, true, platform, pageSection, user);
        }
        return;

      case 'twitter':
        if (state.enabled && !state.token_expired) {
          success(
            dispatch,
            `Successfully connected to ${getPlatformName(Socials.Twitter)}.`,
            platform,
            '', //cssTrackingSource,
            user.id,
            selectPageSection(states)
          );
        } else {
          handleFailedConnect(dispatch, true, platform, '', pageSection, user);
        }
        return;

      case 'tiktok':
        if (state.enabled) {
          success(
            dispatch,
            'Successfully connected to Tiktok.',
            platform,
            '', //cssTrackingSource,
            user.id,
            selectPageSection(states)
          );
        } else {
          handleFailedConnect(dispatch, true, platform, '', pageSection, user);
        }
        return;
    }
  };

// EXTERNAL FUNCTIONS
export const connectSocial =
  (
    platform: string,
    cssTrackingSource: string,
    isFrom: string,
    excludePlatforms: string[] = [],
    showFailurePopup: boolean = true,
    callbackUrl?: string,
    onSuccess?: () => void,
    closeAll?: boolean,
    dontShowUpgradePopup?: boolean,
    skipUpgradeConnect?: boolean
  ): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    try {
      dispatch(
        requestConnectSocial(
          platform,
          'main',
          cssTrackingSource,
          showFailurePopup,
          isFrom,
          excludePlatforms,
          onSuccess,
          closeAll
        )
      );

      const states = getState();

      const user = selectUserInfo(states);
      const isInReconnectMode =
        user.user_accounts[platform].enabled &&
        user.user_accounts[platform].token_expired;

      if (
        !dontShowUpgradePopup &&
        !skipUpgradeConnect &&
        selectIsLimitedConnect(states) &&
        selectIsConntected(states) &&
        !selectConnectedPlatforms(states).includes(platform) &&
        !isInReconnectMode
      ) {
        dispatch(setConnectSocialPage('upgrade-connect'));
        return;
      }

      const isFree = selectIsFreeUser(states);
      const isBasicPlanHidden = selectIsBasicPlanHidden(states);
      const shouldUpgradeTo = doesNeedUpgrade(
        platform,
        isFree,
        user,
        isBasicPlanHidden
      );

      if (!dontShowUpgradePopup && shouldUpgradeTo !== UpgradePlans.none) {
        dispatch(setConnectSocialPage('upgrade', shouldUpgradeTo));
        return;
      }

      if (['instagram', 'facebook-profile'].includes(platform)) {
        dispatch(setConnectSocialPage(platform));
        return;
      }

      if (platform == 'wordpress') {
        dispatch(setConnectSocialPage('wordpress'));
        return;
      }

      if (callbackUrl) {
        startConnect(dispatch, platform, callbackUrl, showFailurePopup);
        return;
      }
      var url = connectPlatformUrl[platform];
      if (platform == 'google_my_business') {
        url = url + '?user_id=' + user.id;
      }

      if (platform == 'twitter') {
        window.open(url, 'popupWindow', 'width=600,height=600,scrollbars=yes');
        let bc = new BroadcastChannel('twitter-connection-channel');
        bc.onmessage = (event) => {
          bc.close();

          dispatch(updateUserInfo(selectUser(states).token, false)).then(
            (user) => {
              dispatch(setConnectSocialLoading(false));
              const state = user.user_accounts[platform];
              const pageSection = selectPageSection(states);

              if (state.enabled && !state.token_expired) {
                success(
                  dispatch,
                  'Successfully connected to Twitter.',
                  platform,
                  cssTrackingSource,
                  user.id,
                  pageSection,
                  onSuccess
                );
              } else {
                handleFailedConnect(
                  dispatch,
                  showFailurePopup,
                  platform,
                  cssTrackingSource,
                  pageSection,
                  user
                );
              }
            }
          );
        };
      } else {
        await openWindow(url);
      }

      dispatch(setConnectSocialLoading(true));
      dispatch(updateUserInfo(selectUser(states).token, false)).then((user) => {
        dispatch(setConnectSocialLoading(false));
        const state = user.user_accounts[platform];
        const pageSection = selectPageSection(states);

        switch (platform) {
          case 'facebook':
          case 'facebook_2':
            if (state.enabled && !state.token_expired) {
              success(
                dispatch,
                'Successfully connected to Facebook.',
                platform,
                cssTrackingSource,
                user.id,
                pageSection,
                onSuccess
              );
            } else {
              getFacebookPages(
                dispatch,
                showFailurePopup,
                platform,
                pageSection,
                user
              );
            }
            return;

          case 'linkedin':
            if (state.enabled && !state.token_expired) {
              success(
                dispatch,
                'Successfully connected to LinkedIn.',
                platform,
                cssTrackingSource,
                user.id,
                pageSection,
                onSuccess
              );
            } else {
              getLinkedinPages(
                dispatch,
                showFailurePopup,
                platform,
                pageSection,
                user
              );
            }
            return;

          case 'twitter':
            if (state.enabled && !state.token_expired) {
              success(
                dispatch,
                `Successfully connected to ${getPlatformName(
                  Socials.Twitter
                )}.`,
                platform,
                cssTrackingSource,
                user.id,
                pageSection,
                onSuccess
              );
            } else {
              handleFailedConnect(
                dispatch,
                false,
                platform,
                cssTrackingSource,
                pageSection,
                user
              );
            }
            return;

          case 'tiktok':
            if (state.enabled) {
              success(
                dispatch,
                'Successfully connected to Tiktok.',
                platform,
                cssTrackingSource,
                user.id,
                pageSection,
                onSuccess
              );
            } else {
              handleFailedConnect(
                dispatch,
                showFailurePopup,
                platform,
                cssTrackingSource,
                pageSection,
                user
              );
            }
            return;

          case 'instagram_business':
            if (state.enabled && !state.token_expired) {
              success(
                dispatch,
                'Successfully Connected to Instagram Business.',
                platform,
                cssTrackingSource,
                user.id,
                pageSection,
                onSuccess
              );
            } else {
              getInstagramBusinessPages(
                dispatch,
                showFailurePopup,
                user,
                pageSection
              );
            }
            return;

          case 'google_my_business':
            if (state.enabled && !state.token_expired) {
              success(
                dispatch,
                'Successfully Connected to Google My business.',
                platform,
                cssTrackingSource,
                user.id,
                pageSection,
                onSuccess
              );
            } else {
              getGoogleBizPages(
                dispatch,
                showFailurePopup,
                platform,
                pageSection,
                user
              );
            }
            return;
          case 'youtube':
            if (state.enabled && !state.token_expired) {
              success(
                dispatch,
                'Successfully Connected to YouTube.',
                platform,
                cssTrackingSource,
                user.id,
                pageSection,
                onSuccess
              );
            } else {
              handleFailedConnect(
                dispatch,
                showFailurePopup,
                platform,
                cssTrackingSource,
                pageSection,
                user
              );
            }
            return;
        }
      });
    } catch (e) {
      const states = getState();
      handleFailedConnect(
        dispatch,
        showFailurePopup,
        platform,
        cssTrackingSource,
        selectPageSection(states),
        selectUserInfo(states)
      );
    }
  };

export const saveFacebookpage =
  (page: FacebookPageItem, platformType: string): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    dispatch(setConnectSocialLoading(true));
    const states = getState();

    const response: AxiosResponse<{
      result: boolean;
    }> = await callPostApi('/social/facebook/save-page', {
      page_id: page.id,
      token: page.token,
      name: page.name,
      platform: platformType,
    });
    if (response.data.result === true) {
      dispatch(updateUserInfo(selectUser(states).token, false)).then((user) => {
        success(
          dispatch,
          'Successfully connected to Facebook.',
          'facebook',
          selectConnectCssTrackingSource(getState()),
          user.id,
          selectPageSection(states)
        );
      });
    } else {
      const states = getState();
      handleFailedConnect(
        dispatch,
        selectConnectShowFailurePopup(states),
        platformType,
        '',
        selectPageSection(states),
        selectUserInfo(states)
      );
    }
  };

export const saveGoogleBizPage =
  (
    page: string,
    title: string,
    platformType: string
  ): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    dispatch(setConnectSocialLoading(true));
    const states = getState();
    const user = selectUser(states);
    const response: AxiosResponse<{
      result: boolean;
    }> = await callPostApi(
      '/v1/agent-dashboard/google-business/location?user_id=' + user.info.id,
      {
        location_id: page,
        location_name: title,
        platform: platformType,
      }
    );
    if (response.status === 200) {
      dispatch(updateUserInfo(selectUser(states).token, false)).then((user) => {
        success(
          dispatch,
          'Successfully connected to Google My Business.',
          'google_my_business',
          selectConnectCssTrackingSource(getState()),
          user.id,
          selectPageSection(states)
        );
      });
    } else {
      const states = getState();
      handleFailedConnect(
        dispatch,
        selectConnectShowFailurePopup(states),
        platformType,
        '',
        selectPageSection(states),
        selectUserInfo(states)
      );
    }
  };

export const saveLinkedinPage =
  (page: LinkedinPageItem): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    dispatch(setConnectSocialLoading(true));
    const states = getState();

    const response: AxiosResponse<{
      result: boolean;
    }> = await callPostApi('/social/linkedin/save-page', {
      id: page.id,
      urn: page.urn,
      name: page.name,
      url: page.url,
    });
    if (response.data.result === true) {
      dispatch(updateUserInfo(selectUser(states).token, false)).then((user) => {
        success(
          dispatch,
          'Successfully connected to Linkedin.',
          'linkedin',
          selectConnectCssTrackingSource(getState()),
          user.id,
          selectPageSection(states)
        );
      });
    } else {
      const states = getState();
      handleFailedConnect(
        dispatch,
        selectConnectShowFailurePopup(states),
        'linkedin',
        '',
        selectPageSection(states),
        selectUserInfo(states)
      );
    }
  };

export const saveInstagramBusinessPage =
  (page: FacebookPageItem): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    dispatch(setConnectSocialLoading(true));
    const states = getState();

    try {
      const response: AxiosResponse<{
        result: boolean;
      }> = await callPostApi('/social/instagram-business/save-page', {
        page_id: page.id,
        page_token: page.token,
      });

      if (response.data.result === true) {
        dispatch(updateUserInfo(selectUser(states).token, false)).then(
          (user) => {
            success(
              dispatch,
              'Successfully connected to Instagram Business.',
              'instagram_business',
              selectConnectCssTrackingSource(getState()),
              user.id,
              selectPageSection(states)
            );
          }
        );
      } else {
        const states = getState();
        handleFailedConnect(
          dispatch,
          selectConnectShowFailurePopup(states),
          'instagram',
          '',
          selectPageSection(states),
          selectUserInfo(states)
        );
      }
    } catch (err) {
      let message = 'Something went wrong, please try again';

      if (err?.response?.data?.result === 'NO_INSTAGRAM_PAGE_CONNECTED') {
        message =
          "There isn't any instagram page connected to this facebook page";
      }

      dispatch(setConnectSocialLoading(false));
      dispatch(failureConnectSocial(message));
    }
  };

export const verifyInstagram =
  (
    username: string,
    password: string,
    save: boolean
  ): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    if (!save) {
      dispatch(failureConnectSocial('Please accept Save my password'));
      return;
    }
    dispatch(setConnectSocialLoading(true));

    const response: AxiosResponse<{
      error: boolean;
      result: any;
    }> = await callPostApi('/social/instagram/verify-credentials', {
      username: username,
      password: password,
    });

    if (response.data.error) {
      if (response.data.result === 'too_many_requests')
        dispatch(
          failureConnectSocial(
            'You can try with this username and password after one hour'
          )
        );
      else
        dispatch(
          failureConnectSocial(
            response.data.result.message ||
              "We couldn't connect to your account, please double check your username and password"
          )
        );
      dispatch(setConnectSocialLoading(false));
      return;
    }

    dispatch(setConnectSocialCredentials(response.data.result));

    checkInstagramProcess({
      refId: response.data.result,
      passWaitForCode: false,
      succeed: () => {
        saveInstagramCredentials(
          dispatch,
          selectConnectCssTrackingSource(getState()),
          selectUserInfo(getState()),
          selectConnectOnSuccess(getState()),
          getState()
        );
      },
      failed: (message?: string) => {
        dispatch(setConnectSocialLoading(false));
        dispatch(
          failureConnectSocial(
            message ||
              "We couldn't connect to your account, please double check your username and password"
          )
        );
      },
      securityCheck: (status: string) => {
        dispatch(setConnectSocialLoading(false));
        if (status === 'WAIT_FOR_NOTIFICATION_APPROVAL') {
          dispatch(setConnectSocialPage('instagram-notification-approval'));
        } else {
          dispatch(setConnectSocialPage('security-code'));
        }
      },
    });
  };

export const verifyFacebookProfile =
  (
    username: string,
    password: string,
    save: boolean
  ): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    if (!save) {
      dispatch(failureConnectSocial('Please accept Save my password'));
      return;
    }
    dispatch(setConnectSocialLoading(true));

    const userInfo = selectUserInfo(getState());

    try {
      const response: AxiosResponse<{
        status: string;
        message: string;
        data: { request_id: string };
      }> = await callPostApi(
        '/v1/user-social-accounts/facebook-profile-login',
        {
          userId: userInfo.id,
          username: username,
          password: password,
          handle_2fa: 1,
        }
      );

      const reqId = response.data.data.request_id;

      if (empty(reqId) || response.data.status !== 'success') {
        dispatch(
          failureConnectSocial(
            response.data.message ??
              'Something went wrong while trying to login to your Facebook Personal!'
          )
        );
        dispatch(setConnectSocialLoading(false));
        return;
      }

      dispatch(setConnectSocialCredentials(reqId, username, password, '', ''));
      const states = getState();

      checkFacebookProfileLoginStatus({
        reqId: reqId,
        userId: userInfo.id,
        username: username,
        succeed: () => {
          dispatch(updateUserInfo(selectUser(states).token, false)).then(
            (user) => {
              success(
                dispatch,
                'Successfully connected to Facebook Profile.',
                'facebook-profile',
                selectConnectCssTrackingSource(states),
                userInfo.id,
                selectConnectOnSuccess(states)
              );
            }
          );
        },
        failed: (message: string) => {
          dispatch(setConnectSocialPage('facebook-profile'));
          dispatch(setConnectSocialLoading(false));
          dispatch(failureConnectSocial(message));
          setTimeout(function () {
            handleFailedConnect(
              dispatch,
              selectConnectShowFailurePopup(states),
              'facebook-profile',
              '',
              selectPageSection(states),
              selectUserInfo(states),
              message
            );
          }, 8000);
        },
        securityCheck: (message, status_code) => {
          dispatch(setConnectSocialLoading(false));
          dispatch(
            setConnectSocialCredentials(reqId, username, password, '', message)
          );
          if (status_code === 1002) {
            // 1002 Device Approval
            dispatch(
              setConnectSocialPage('facebook-profile-2fa-device-approval')
            );
          } else {
            // 1001 General | 1003: Text | 1004: Email | 1005: Authenticator App | 1006: Whatsapp | 1007: Code Backup
            dispatch(setConnectSocialPage('facebook-profile-2fa-code'));
          }
        },
      });
    } catch (e) {
      const { default: axios } = await import('axios');
      let errorMsg =
        'Something went wrong while trying to login to your Facebook Personal!';
      if (axios.isAxiosError(e) && e.response?.data?.message) {
        errorMsg = e?.response?.data?.message;
      }
      dispatch(failureConnectSocial(errorMsg));
      dispatch(setConnectSocialLoading(false));
      return;
    }
  };

const checkFacebookProfileLoginStatus = async (options: {
  reqId: string;
  userId: number;
  username: string;
  succeed: any;
  failed: (message: string) => void;
  securityCheck: (message: string, status_code: number) => void;
  isRetryOnError?: boolean;
}) => {
  try {
    const response: AxiosResponse<{
      status: number;
      message: string;
      data: {
        request_id: string;
        status: number;
        progress: string;
        is_successful: boolean;
      };
    }> = await callPostApi(
      '/v1/user-social-accounts/facebook-profile-login-check-status',
      {
        req_id: options.reqId,
        userId: options.userId,
        username: options.username,
      }
    );

    const { status, progress, is_successful } = response.data.data;
    const message = response.data.message;

    if (progress !== '100%') {
      setTimeout(() => {
        checkFacebookProfileLoginStatus(options);
      }, 5000);
      if (status > 1000 && status < 2000) {
        options.securityCheck(message, status);
      }
    } else {
      is_successful ? options.succeed() : options.failed(message);
    }
  } catch (e) {
    console.error(e);
    if (options.isRetryOnError) {
      options.failed('Something went wrong, please try again later!');
    } else {
      options.isRetryOnError = true;
      setTimeout(() => {
        checkFacebookProfileLoginStatus(options);
      }, 5000);
    }
  }
};

export const sendFacebookProfileSecurityCode =
  (
    username: string,
    password: string,
    code: string
  ): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    if (!code) {
      dispatch(failureConnectSocial('Please enter security code'));
      return;
    }
    dispatch(setConnectSocialLoading(true));

    const response: AxiosResponse<{
      error: boolean;
      result: any;
    }> = await callPostApi(
      '/v1/user-social-accounts/facebook-profile-login-send-2fa-code',
      {
        username,
        code,
      }
    );
  };

export const sendSecurityCode =
  (
    username: string,
    password: string,
    code: string,
    refId: any
  ): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    if (!code) {
      dispatch(failureConnectSocial('Please enter security code'));
      return;
    }
    dispatch(setConnectSocialLoading(true));

    const response: AxiosResponse<{
      error: boolean;
      result: any;
    }> = await callPostApi('/social/instagram/send-security-code', {
      username,
      code,
    });
    const states = getState();

    setTimeout(() => {
      checkInstagramProcess({
        refId,
        passWaitForCode: true,
        securityCheck: undefined,
        succeed: () => {
          saveInstagramCredentials(
            dispatch,
            selectConnectCssTrackingSource(states),
            selectUserInfo(states),
            selectConnectOnSuccess(states),
            states
          );
        },
        failed: (message?: string) => {
          dispatch(setConnectSocialPage('instagram'));
          dispatch(
            failureConnectSocial(
              message || "We couldn't connect to your account, please try again"
            )
          );
          handleFailedConnect(
            dispatch,
            selectConnectShowFailurePopup(states),
            'instagram',
            '',
            selectPageSection(states),
            selectUserInfo(states)
          );
        },
      });
    }, 10000);
  };

export const afterInstagramSave =
  (platform: 'instagram' | 'instagram_business'): ConnectSocialThunkResult =>
  async (dispatch: ConnectSocialThunkDispatch, getState: () => RootState) => {
    const states = getState();

    const user = selectUserInfo(states);

    /**
     * Checking for next level to complete instagram business or done the process.
     */
    if (platform === 'instagram') {
      if (
        !user.user_accounts['instagram_business'].enabled ||
        (user.user_accounts['instagram_business'].enabled &&
          user.user_accounts['instagram_business'].token_expired)
      ) {
        dispatch(setConnectSocialPage('instagram-connect-another-account'));
      } else {
        dispatch(setConnectSocialPage('instagram-done'));
      }
    } else {
      if (platform === 'instagram_business') {
        if (
          !user.user_accounts['instagram'].enabled ||
          (user.user_accounts['instagram'].enabled &&
            user.user_accounts['instagram'].token_expired)
        ) {
          dispatch(setConnectSocialPage('instagram-connect-another-account'));
        } else {
          dispatch(setConnectSocialPage('instagram-done'));
        }
      }
    }
  };
