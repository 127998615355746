import { capitalize } from '@/core/helpers';
import type { IUser } from '@/types/user';
import { isEmptyString } from '@/src/shared/utils/string';

export const getPlatformName = (platform: string): string => {
  if (isEmptyString(platform)) {
    return '';
  }
  const lowerCasePlatformName = platform.toLowerCase();
  switch (lowerCasePlatformName) {
    case 'linkedin':
      return 'LinkedIn';
    case 'tiktok':
      return 'TikTok';
    case 'facebook':
      return 'Facebook';
    case 'facebook_2':
      return 'Facebook Business 2';
    case 'facebook-profile':
      return 'Facebook Personal';
    case 'youtube':
      return 'YouTube';
    case 'instagram_business':
      return 'Instagram Business';
    case 'instagram':
      return 'Instagram';
    case 'twitter':
      return 'X (Twitter)';
    case 'google_my_business':
      return 'Google My Business';
    default:
      return capitalize(lowerCasePlatformName);
  }
};

export function isPlatformConnected(
  socialId: string,
  accounts: IUser['user_accounts']
) {
  return accounts[socialId].enabled;
}

export function isPlatformNeedsReConnect(
  socialId: string,
  accounts: IUser['user_accounts']
) {
  return accounts[socialId].enabled && accounts[socialId].token_expired;
}
