// import { getBrokerageName } from '@/src/containers/TrialPopups/AvantiUpgradeToTrialPopup/utils/getBrokerageName';
import { IUser } from '@/types/user';
// import { isUserSubscriptionFree } from '@/src/core/utils/user';
// import { shouldSkipPro } from './shouldSkipPro';

// const ALLOWED_BROKERAGES = ['Avanti Way Realty', 'ReMax Realty Services'];
// const ALLOWED_BROKERAGES_IDS = [
//   26, // Avanti Way
//   48, // ReMax Realty Services
// ];
/**
 * Determines whether a user from a supported brokerage is eligible for a trial.
 * @param user - The user to check for eligibility.
 * @returns True if the user's brokerage is supported, false otherwise.
 */
export function isEligibleForBrokerageTrial(user: IUser): boolean {
  return false;
  // const userBrokerage = getBrokerageName(user.brokerage_id);
  // return (
  //   isUserSubscriptionFree(user) &&
  //   ALLOWED_BROKERAGES_IDS.includes(user.brokerage_id)
  // );
}

/**
 * Determines whether a user is eligible for a trial subscription.
 * @param user - The user to check for trial eligibility.
 * @param selectedPlan - The plan user wants to upgrade
 * @returns True if the user is eligible, false otherwise.
 */
export function isUserEligibleForTrial(
  user: IUser,
  selectedPlan: string = undefined
): boolean {
  return false;
  // const isUserTrialType = user.trial.type === 'USER';
  //
  // let skipPro = shouldSkipPro(user);
  // let plan = normalizeSelectedPlan(selectedPlan);
  //
  // // Make sure premium user can have trial on premium
  // if (plan && plan == 'premium') {
  //   if (user.trial.can_be_activated_plan !== 'premium') return false;
  // }
  //
  // // Make sure basic user can have trial on basic or premium
  // if (plan && plan == 'basic') {
  //   if (
  //     user.trial.can_be_activated_plan !== 'basic' &&
  //     user.trial.can_be_activated_plan !== 'premium'
  //   )
  //     return false;
  // }
  //
  // // We don't have trial on other plans
  // if (plan && plan !== 'basic' && plan != 'premium') return false;
  //
  // let canHaveTrial =
  //   (user.trial.can_be_activated &&
  //     isUserTrialType &&
  //     isUserSubscriptionFree(user) &&
  //     !user.trial.is_trial) ||
  //   (!user.trial.is_trial &&
  //     isUserSubscriptionFree(user) &&
  //     user?.register_source === 'try-roomvu-free-trial');
  //
  // if (canHaveTrial) {
  //   // Make sure skip pro users have premium trial, otherwise cancel trial
  //   if (skipPro) {
  //     return user.trial.can_be_activated_plan === 'premium';
  //   }
  //
  //   return true;
  // }
  //
  // return false;
}

// function normalizeSelectedPlan(selectedPlan: string = undefined) {
//   let plan = selectedPlan?.toLowerCase();
//   if (plan == 'pro') return 'basic';
//
//   return plan;
// }
