import type { IUser } from '@/types/user';
import { empty } from '@/core/helpers';
import { userHasLocation } from '@/src/containers/AgentDashboard/hooks/useUserHasLocation';

const threshold = 3;
const sessionVariableKey = 'p-c';

export function shouldAskForProfileCompletion(user: IUser) {
  const countThatAsked = JSON.parse(sessionStorage.getItem(sessionVariableKey));
  const phoneOrLocationIsEmpty = empty(user?.phone) || !userHasLocation(user);
  const isShowLimitReached = countThatAsked >= threshold;

  if (phoneOrLocationIsEmpty && !isShowLimitReached) {
    sessionStorage.setItem(
      sessionVariableKey,
      JSON.stringify(countThatAsked + 1)
    );
  }

  return phoneOrLocationIsEmpty && !isShowLimitReached;
}

export function dontAskForProfileCompletion() {
  sessionStorage.setItem(sessionVariableKey, JSON.stringify(threshold + 1));
}
