import config from '@/config';

const baseUrl =
  config.websiteBaseUrl === 'http://localhost:3000'
    ? 'https://staging.roomvu.com'
    : config.websiteBaseUrl;
export const connectPlatformUrl = {
  facebook: baseUrl + '/login/facebook?platform=facebook',
  facebook_2: baseUrl + '/login/facebook?platform=facebook_2',
  linkedin: baseUrl + '/login/linkedin',
  twitter: baseUrl + '/login/twitter',
  tiktok: baseUrl + '/login/tiktok',
  instagram_business: baseUrl + '/login/instagram-business',
  youtube: baseUrl + '/api/v1/agent-dashboard/youtube/grant',
  google_my_business: baseUrl + '/api/v1/agent-dashboard/google-business/grant',
};
