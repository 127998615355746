import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/redux/user/selectors';
import type { IUser } from '@/types/user';
import { empty } from '@/core/helpers';

export function userHasLocation(user: IUser) {
  return !empty(user?.ads_city) && !empty(user?.ads_region);
}

export function useUserHasLocation() {
  const user = useSelector(selectUserInfo);
  return { hasLocation: userHasLocation(user) };
}
