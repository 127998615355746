import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../reducer';

export const connectSocialSelector = (state: RootState) => state.connectSocial;

export const selectConnectSocialPage = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.page
);

export const selectConnectSocialCredentials = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.credentials
);

export const selectConnectSocialUpgradePlan = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.plan
);

export const selectConnectSocialLoading = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.loading
);

export const selectConnectSocialStatus = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.status
);

export const selectConnectSocialFacebookPages = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.facebookPages
);

export const selectConnectSocialGoogleBizPages = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.googleBizPages
);

export const selectConnectSocialLinkedinPages = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.linkedinPages
);
export const selectConnectSocialFacebookPageSaved = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.facebookPageSaved
);

export const selectConnectSocialInstagramBusinessPages = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.instagramBusinessPages
);

export const selectConnectSocialError = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.error
);

export const selectConnectCssTrackingSource = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.cssTrackingSource
);

export const selectConnectOnSuccess = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.onSuccess
);

export const selectConnectCloseAll = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.closeAll
);

export const selectConnectShowFailurePopup = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.showFailurePopup
);

export const selectConnectSocialIsFrom = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.isFrom
);

export const selectConnectSocialExcludePlatforms = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.excludePlatforms
);

export const selectPlatform = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.platform
);

export const selectConnectSocialPlatformType = createSelector(
  connectSocialSelector,
  (connectSocial) => connectSocial.platformType
);
