import { IUser } from '@/types/user';

const BROKERAGE_IDS = {
  avanti: 26,
  remax: 48,
};

export function getBrokerageName(brokerageId: IUser['brokerage_id']) {
  if (brokerageId === BROKERAGE_IDS.avanti) {
    return 'Avanti Way Realty';
  }
  if (brokerageId === BROKERAGE_IDS.remax) {
    return 'ReMax Realty Services';
  }
  return null;
}
