export enum UpgradeIntroType {
  General,
  Connect,
  AllPlans,
  AdsPayment,
}

export enum TrialType {
  TwoWeeks = '2week',
  ThreeMonths = '3month',
}

export enum TrialPlanType {
  Basic = 'basic',
  Premium = 'premium',
}
